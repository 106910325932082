import type { CreateCashAccountParams } from "mkm-api";
import { InputData } from "~/components/Content/Account/Cash/CashAccountInterfaces";
import { useConfig } from "~/constants";

// Default Cash Account Data
const defaultCashAccountData: InputData = {
  email: "",
  firstName: "",
  lastName: "",
  companyName: "",
  mobile: "",
  landline: "",
  password: "",
  inTrade: null,
  accountBranchId: 0,
  accountBranchName: "",
  profession: "",
  address: {
    line1: "",
    line2: "",
    line3: "",
    city: "",
    county: "",
    postcode: "",
  },
  preferences: {
    email: false,
    phone: false,
    sms: false,
    post: false,
  },
  termsAndConditions: false,
  success: false,
};

// State Management
const cashAccountData = useState<InputData>("cashAccountData", () => ({
  ...defaultCashAccountData,
}));

// Reset Cash Account Data
const resetCashAccountData = () => {
  cashAccountData.value = { ...defaultCashAccountData };
};

const registerCashAccount = async (payload: InputData) => {
  const { baseUrl, headers } = useConfig();

  const mappedPayload: CreateCashAccountParams = {
    preferredBranch: payload.accountBranchId,
    accountBusinessType: payload.profession,
    accountAddress1: payload.address.line1,
    accountAddress2: payload.address.line2,
    accountAddress3: payload.address.line3,
    accountCity: payload.address.city,
    accountCounty: payload.address.county,
    accountPostcode: payload.address.postcode,
    companyName: payload.companyName,
    email: payload.email,
    password: payload.password,
    profileFirstName: payload.firstName,
    profileLastName: payload.lastName,
    profileLandline: payload.landline,
    profileMobile: payload.mobile,
    isEmailPreferredContact: payload.preferences.email,
    isPostPreferredContact: payload.preferences.post,
    isPhonePreferredContact: payload.preferences.phone,
    isSMSPreferredContact: payload.preferences.sms,
  };

  const { error } = await useAsyncData("createCashAccount", () =>
    $fetch<any>(`${baseUrl}/createCashAccount`, {
      headers,
      body: JSON.stringify(mappedPayload),
      method: "POST",
      credentials: "include",
    }),
  );

  useHandleError(error.value);
};

export function useCashAccount() {
  return {
    registerCashAccount,
    cashAccountData,
    resetCashAccountData,
  };
}
